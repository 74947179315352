import { useField, useForm } from "vee-validate";
import * as yup from "yup";

export default function () {
    const signInSchema = yup.object({
        email: yup.string().required().email(),
    });
    const appointmentRequestSchema = {
        // brands(value) {
        //     if (value && value.trim()) {
        //         return true;
        //     }
        //     return 'This is required';
        // },
        // duration(value) {
        //     if (value && value.trim()) {
        //         return true;
        //     }
        //     return 'This is required';
        // },
        brands: yup.array().required(),
        duration: yup.object().required(),
        date: yup.date().required(),
        time: yup.mixed().required(),

    }

    const signInForm = useForm({
        validationSchema: signInSchema,
    });
    const appointmentRequestForm = useForm({
        validationSchema: appointmentRequestSchema,
    });

    const { value: email, errorMessage: emailError } = useField("email");
    const { value: brands, errorMessage: brandError } = useField("brands");
    const { value: duration, errorMessage: durationError } = useField("duration");
    const { value: date, errorMessage: dateError } = useField("date");
    const { value: time, errorMessage: timeError } = useField("time");

    return {
        signInForm,
        appointmentRequestForm,
        email,
        emailError,
        appointmentRequestSchema,
        brands,
        brandError,
        duration,
        durationError,
        date,
        dateError,
        time,
        timeError,
    }
}