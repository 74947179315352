<template>
    <div class="request-appointment-component">
        <div class="header">
            <button-component
                type="white-icon-btn"
                class="back-btn mr-1"
                @click="$router.go(-1)"
            >
                <ArrowLeftIcon
                    class="text-gray-500"
                    aria-hidden="true"
                />
            </button-component>
            <h2>Request an appointment</h2>
        </div>
        <info-box-component
            v-if="hasNoBalance"
            type="danger"
            title="Not enough credits"
            sub-title="At least <span>1 credit</span> is needed to request an appointment."
        />
        <form class="space-y-4">
            <!-- APPOINTMENT DATE -->
            <div class="appointment__date">
                <label for="date-picker" class="text-sm leading-5 font-medium text-gray-700 mb-1 block">Appointment Date</label>
                <datepicker
                    v-if="!_.isEmpty(doctorSchedule)"
                    v-model="date"
                    v-bind="datePickerProps"
                    @selected="onSelected($event)"
                />
                <p v-if="!_.isEmpty(doctorSchedule)" class="validation-error">{{ dateError }}</p>
            </div>

            <div v-if="!_.isEmpty(doctorSchedule)">
                <div v-if="!_.isEmpty(availableSched)">
                    <p class="text-sm leading-5 font-medium text-gray-700 mb-3">Doctor’s available schedule</p>
                    <div class="appointment__action">
                        <div class="grid grid-cols-2 gap-2">
                            <button-component
                                v-for="(sched, index) in availableSched.schedules"
                                :key="index"
                                v-bind="availableScheduleButtonProps(sched)"
                                @click="setSchedule(sched)"
                            />
                        </div>
                        <span v-if="!_.isEmpty(doctorSchedule) && date" class="block mb-4 validation-error">{{ timeError }}</span>
                    </div>
                </div>
                <p class="appointment__date-description">The doctor may reschedule the appointment date and time</p>
            </div>
            <p v-else class="text-gray-500 text-xs leading-5">The doctor will schedule the appointment date and time</p>

            <div class="border-t border-t-gray-200" />

            <!-- BRANDS -->
            <multiple-select-component
                v-model="brands"
                v-bind="brandProps"
                @on-load-data="getBrandList"
            />
            <span class="validation-error">{{ brandError }}</span>

            <!-- CREDIT NEEDED -->
            <multiple-select-component
                v-model="duration"
                v-bind="creditProps"
                @on-load-data="getCreditPerMinute"
            />
            <span class="validation-error">{{ durationError }}</span>

            <!-- NOTE TO DOCTOR -->
            <input-field-component
                v-model="form.message"
                v-bind="inputFieldProps"
            />

            <div class="action-btn">
                <button-component
                    v-bind="nextButtonProps"
                    @click="onValidateForm"
                />
            </div>
        </form>
    </div>
</template>

<script>
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import UseValidation from "@GlobalFunctions/useValidation.js";
import {
    MultipleSelectComponent,
    InputFieldComponent,
    Datepicker,
    InfoBoxComponent,
} from '@CommonComponents';
import { computed, ref, reactive, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import moment from "moment";
import { CalendarIcon } from "@heroicons/vue/solid";

export default {
    name: 'RequestAppointmentComponent',
    components: {
        ArrowLeftIcon,
        MultipleSelectComponent,
        InputFieldComponent,
        Datepicker,
        InfoBoxComponent,
    },
    emits: [ "on-show-preview" ],
    setup(props, { emit }) {
        const store = useStore();

        // VALIDATION DATA
        const {
            brands,
            brandError,
            duration,
            durationError,
            date,
            dateError,
            time,
            timeError,
            appointmentRequestForm,
            appointmentRequestSchema,
        } = UseValidation();

        // CREDIT BALANCE
        const hasNoBalance = computed(
            () => store.getters["CommonCredit/hasNoBalance"]
        );
        const isInsufficientBalance = computed(
            () => store.getters["CommonCredit/isInsufficientBalance"]
        );
        const getUserCredits = async () => {
            await store.dispatch("CommonCredit/getUserCredits");
        }
        onMounted(() => {
            getUserCredits();
        })

        // START OF SCHEDULE SELECTION
        const doctorSchedule = computed(() => {
            return store.getters['CommonDoctor/doctorSchedule']
        })

        const calendarIcon = ref(CalendarIcon)

        const today = new Date();
        const after48Hrs = new Date(today.setDate(today.getDate() + 2));
        // SET UNAVAILABLE DATES
        const disabledDates = ref(
            {
                to: after48Hrs,
                customPredictor (date) {
                    const days = {
                        "Mon": "Monday",
                        "Tue": "Tuesday",
                        "Wed": "Wednesday",
                        "Thu": "Thursday",
                        "Fri": "Friday",
                        "Sat": "Saturday",
                        "Sun": "Sunday",
                    }
                    const docsched = _.map(doctorSchedule.value, i => days[i.day])
                    const eachDay = moment(date).format('dddd')
                    if (!_.includes(docsched, eachDay)) {
                        return true
                    }
                },
            }
        )
        // SET UNAVAILABLE MONTHS AND YEARS
        const disablePreviousMonthYear = ref(
            {
                to: new Date(today.setDate(today.getDate()))
            }
        );
        const selected = ref("");
        const selectedDate = ref({});
        const selectedTime = ref({});
        const availableSched = ref(null);
        // DISPLAY AVAILABLE TIME SCHEDULE
        const onSelected = (date) => {
            // TO REMOVE ACTIVE CLASS FOR ONE OPTION TIME SCHEDULE
            const button = document.querySelector(".appointment__action .time-option")
            if (button) {
                button.classList.remove("active");
            }
            // TO AVOID SHOWING ERROR MESSAGE ON INITIAL LOAD OF TIME OPTIONS
            if (!_.isEmpty(time.value)) {
                time.value = null;
            }

            selectedDate.value.shortdate = moment(date).format("YYYY/MM/DD");
            selectedDate.value.longdate = moment(date).format("ddd, Do MMM YYYY");

            const day = moment(date).format('ddd')
            availableSched.value = _.find(doctorSchedule.value, i => i.day === day)
        }
        // SET CURRENT STATE OF BUTTON
        const current = ref(null)
        const setSchedule = (sched) => {
            time.value = sched
            selectedTime.value.time = sched
            current.value = sched.id
        }
        // END OF SCHEDULE PICKER

        // START OF BRAND SELECTION
        const brand = ref([])
        const getBrandList = async () => {
            if (_.isEmpty(brandList.value)) {
                await store.dispatch('CommonAppointment/getBrandList')
            }
        }
        const brandList = computed(() => {
            return store.getters['CommonAppointment/brandList']
        })
        const brandLoading = computed(() => {
            return store.getters['CommonAppointment/brandLoading']
        })
        const brandProps = reactive({
            id: "brand",
            mode: "multiple",
            object: true,
            label: "name",
            valueProp: "id",
            trackBy: "name",
            labelFor: "Brands to promote",
            options: brandList,
            placeholder: "Select brand",
            showTagList: true,
            loading: brandLoading,
        })
        watch(
            () => brands.value,
            (newVal) => {
                if (newVal) {
                    form.value.brands = newVal
                } else {
                    form.value.brands = []
                }
            }
        )
        // END OF BRAND SELECTION

        // START OF CREDIT DURATION SELECTION
        const creditList = computed(() => store.getters['CommonCredit/creditPerMinute']);
        const creditLoading = computed(() => store.getters['CommonAppointment/creditLoading'])
        const credit = ref({})
        const creditFormat = ref({})
        const creditProps = reactive({
            id: "credit",
            mode: "single",
            object: true,
            options: creditList,
            label: "label",
            valueProp: "credit",
            trackBy: "label",
            labelFor: "How much time do you need?",
            placeholder: "Select duration",
            loading: creditLoading,
        })
        const getCreditPerMinute = async () => {
            if (_.isEmpty(creditList.value)) {
                await store.dispatch('CommonCredit/getCreditPerMinute')
            }
        }
        watch(
            () => duration.value,
            (newVal) => {
                if (newVal) {
                    form.value.credits = {
                        count: newVal.credit,
                        credit_use: newVal.credit > 1 ? `${newVal.credit} Credits` : `${newVal.credit} Credit`,
                    }
                } else {
                    form.value.credits = {}
                }
            }
        )
        // END OF CREDIT DURATION SELECTION

        // FORM VALUE
        const form = ref({
            status: "pending",
            appointment_date: selectedDate.value,
            appointment_time: selectedTime.value,
            brands: [],
            credits: creditFormat.value,
            message: ""
        })

        // START OF NEXT BUTTON PROPERTIES
        const disableButton = ref(false)
        const nextButtonLoading = ref(false)
        const nextButtonProps = reactive({
            type: "primary-btn",
            label: "Next",
            loading: nextButtonLoading,
            disabled: disableButton,
        })
        // END OF NEXT BUTTON PROPERTIES

        // START OF FORM VALIDATION
        const onValidateForm = async () => {
            nextButtonLoading.value = true;
            // DO NOT INCLUDE DATE AND TIME SCHEMA WHEN APPOINTMENT SCHED IS EMPTY
            if (_.isEmpty(doctorSchedule.value)) {
                delete appointmentRequestSchema.date
                delete appointmentRequestSchema.time
            }
            const isFormDirty = await appointmentRequestForm.validate();
            if (isFormDirty.valid) {
                await getUserCredits();
                if (hasNoBalance.value || (form.value.credits.count === 2 && isInsufficientBalance.value)) {
                    disableButton.value = true
                    return
                }
                const payload = {
                    form: form.value,
                    show: true
                }
                emit("on-show-preview", payload)
            }
            nextButtonLoading.value = false;
        }
        // END OF FORM VALIDATION

        // COMPONENT PROPERTIES
        const datePickerProps = computed(() => {
            return {
                id: "date-picker",
                disabledDates: disabledDates.value,
                disabledMonths: disablePreviousMonthYear.value,
                disabledYears: disablePreviousMonthYear.value,
                placeholder: "Select Date",
                minimumView: "day",
                maximumView: "year",
                calendarButtonIcon: calendarIcon.value,
                format: "D, dd MMM yyyy"
            }
        })

        const availableScheduleButtonProps = (sched) => {
            return {
                id: sched.id,
                type: "white-btn",
                label: sched.label,
                size: "s",
                class: `time-option py-3 ${current.value === sched.id ? 'active' : ''}`
            }
        }

        const inputFieldProps = computed(() => {
            return {
                label: "Note to doctor",
                optionalLabel: "Optional",
                type: "textarea",
                class: "mb-2",
                placeholder: "Say something about your brand",
                prependIcon: false,
                rows: 4
            }
        })

        return {
            doctorSchedule,
            disabledDates,
            availableSched,
            onSelected,
            setSchedule,
            brand,
            brandList,
            brandProps,
            getBrandList,
            credit,
            creditList,
            creditProps,
            getCreditPerMinute,
            onValidateForm,
            form,
            brandError,
            brands,
            duration,
            durationError,
            current,
            date,
            dateError,
            time,
            timeError,
            selectedDate,
            selectedTime,
            selected,
            calendarIcon,
            hasNoBalance,
            datePickerProps,
            availableScheduleButtonProps,
            inputFieldProps,
            nextButtonProps,
        }
    }
}
</script>

<style lang="scss">
.request-appointment-component {
    @apply space-y-5;
    .header {
        @apply flex items-center;
    }
    .appointment {
        &__date {
        }
        &__date-description {
            @apply text-sm leading-5 font-normal text-gray-500;
        }
        &__action {
            .active {
                @apply bg-primary-50 text-primary outline-none border border-transparent;
            }
        }
    }
    .action-btn {
        @apply flex justify-end pb-6;
        button {
            min-width: 66px;
        }
    }
    .back-btn {
        &:focus {
            @apply shadow-none outline-none ring-0 ring-offset-0 ring-transparent;
        }
    }
    .input-field-component {
        .label {
            @apply text-sm leading-5 font-medium text-gray-700;
        }
    }
}
.validation-error {
    @apply text-xs leading-5 font-normal text-red-500;
}

</style>